import { useUserMetadata } from 'admin-portal-shared-services';
import { Error404 } from 'components/Error/404/404';
import {
  DEFAULT_PAGE_SIZE,
  SEGMENT_ANALYTICS_APP_NAME,
  SEGMENT_ANALYTICS_PERMISSION_GROUP,
} from 'config/constants';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptySplitApi, useGetStoreDetailsQuery, useGetStoreVendorsQuery } from 'services/baseApi';
import { GetStoreVendorsParams } from 'services/stores';
import { Loading } from 'theme/globalStyles';
import StoreDetailsPageView from './StoreDetailsPageView';

export default function StoreDetailsPage(): JSX.Element {
  const { country, id } = useParams<{ country: string; id: string }>();
  const dispatch = useDispatch();
  const { data } = useUserMetadata();
  const analytics = useTrackingSegment();

  const [page, setPage] = useState(0);
  const [vendorSearch, setVendorSearch] = useState<
    Pick<GetStoreVendorsParams, 'name' | 'vendorId'>
  >({ name: undefined, vendorId: undefined });

  const shouldSearchVendorName = Boolean(vendorSearch.name && vendorSearch.name.length >= 3);

  const {
    data: store,
    isLoading: isLoadingStore,
    isFetching: isFetchingStore,
  } = useGetStoreDetailsQuery({ country: country!, id: id! }, { skip: !country });

  const {
    data: vendors,
    isLoading: isLoadingVendors,
    isFetching: isFetchingVendors,
    isError: isErrorVendors,
  } = useGetStoreVendorsQuery(
    {
      country: country!,
      id: id!,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      name: shouldSearchVendorName ? vendorSearch.name : undefined,
      vendorId: vendorSearch.vendorId,
    },
    { skip: !country }
  );

  useEffect(() => {
    return () => {
      dispatch(emptySplitApi.util.resetApiState());
    };
  }, []);

  useEffect(() => {
    setPage(0);
  }, [country, vendorSearch]);

  useEffect(() => {
    if (!store) return;
    if (!data) return;

    analytics.track('Page Viewed', {
      appName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageName: 'Store Details',
      pageLabel: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      storeId: store.id,
      storeCountry: country,
      storeName: store.name,
    });
  }, [store, data, country, id]);

  function handleOnChangePage(currentPage: number) {
    setPage(currentPage - 1);
  }

  if (isLoadingStore || isFetchingStore) return <Loading />;

  if (!isLoadingStore && !isFetchingStore && !store) return <Error404 />;

  return (
    <StoreDetailsPageView
      store={store}
      vendors={!isErrorVendors ? vendors?.content ?? [] : []}
      isFetching={isFetchingVendors}
      isLoading={isLoadingVendors}
      setVendorSearch={setVendorSearch}
      displaySearchInfo={Boolean(vendorSearch.name && vendorSearch.name.length < 3)}
      pagination={
        !!vendors?.pagination && {
          current: vendors.pagination.page + 1,
          total: vendors.pagination.totalElements,
          onChange: handleOnChangePage,
        }
      }
    />
  );
}

import {
  CountryData,
  useAuthenticationService,
  useCountryService,
} from 'admin-portal-shared-services';
import { CountryLangType } from 'admin-portal-shared-services/dist/hooks/useCountryService/useCountryService.types';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetSupportedCountries } from './useGetSupportedCountries';
import usePreferredLanguage from './usePreferredLanguage';

export function useCountries() {
  const authentication = useAuthenticationService();
  const { data: countriesData, isLoading } = useCountryService();
  const preferredLanguage = usePreferredLanguage();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const { country: countryParam } = useParams<{ country?: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const supportedCountries = useGetSupportedCountries();

  const initialCountry = countryParam ?? userCountry;

  const handleCountryParam = useCallback(
    (countryCode: string) => {
      navigate(location.pathname.replace(/[A-Z]{2}/, countryCode), { replace: true });
    },
    [navigate, location]
  );

  const getCountryName = useCallback(
    (countryCode: string) => {
      const countries = (countriesData ?? []) as CountryData[];

      const foundCountry = countries.find((c) => c.code === countryCode);

      const language = preferredLanguage.split('-')[0] as keyof CountryLangType;

      return foundCountry?.name?.[language] ?? foundCountry?.name?.en ?? countryCode;
    },
    [countriesData, preferredLanguage]
  );

  return { supportedCountries, handleCountryParam, getCountryName, initialCountry, isLoading };
}

import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { store as appStore } from 'app/store';
import { StoreStatus } from 'domains/StoreStatus';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import differenceWith from 'lodash/differenceWith';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import toPairs from 'lodash/toPairs';
import { RefObject, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpdateStoreMutation } from 'services/baseApi';
import { GetStoreAvailableVendorsResponse, StoreDetails, UpdateStoreParams } from 'services/stores';
import { tags } from 'services/tags';
import { getDifferentKeys } from 'utils';
import * as yup from 'yup';

export interface SettingsForm extends Omit<UpdateStoreParams, 'id' | 'country' | 'logData'> {
  status: StoreStatus;
}

interface UseDetailsFormReturn {
  form: FormikProps<SettingsForm>;
  onSubmit: FormikProps<SettingsForm>['submitForm'];
  formChanged: boolean;
}

export interface UseSettingsFormProps {
  store?: StoreDetails;
  country: string;
  imageRef: RefObject<HandleFileUpload>;
  promotionsRef: RefObject<HandleFileUpload>;
  miscellaneousRef: RefObject<HandleFileUpload>;
}

interface HandleGenerateImageProps {
  name: string;
  form: FormikHelpers<SettingsForm>;
  generate?: () => Promise<string>;
  value?: string | null;
}

export const useSettingsForm = ({
  store,
  country,
  imageRef,
  promotionsRef,
  miscellaneousRef,
}: UseSettingsFormProps): UseDetailsFormReturn => {
  const [updateStores] = useUpdateStoreMutation();
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const availableVendors = useMemo(() => {
    return (
      (Object.values(appStore.getState().api.queries).find(
        (value) => value?.endpointName === tags.getStoreAvailableVendors[0]
      )?.data as GetStoreAvailableVendorsResponse) ?? []
    );
  }, [appStore.getState().api.queries]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required(formatMessage({ id: 'STORE_SETTINGS_PAGE.REQUIRED_STORE_NAME' })),
        defaultVendorId: yup.string().required(),
        image: yup.string().nullable().notOneOf(['invalid']),
        status: yup.string(),
        configurations: yup.object().shape({
          promotions: yup.object().shape({
            image: yup.string().nullable().notOneOf(['invalid']),
            enabled: yup.boolean(),
          }),
          miscellaneous: yup.object().shape({
            image: yup.string().nullable().notOneOf(['invalid']),
            enabled: yup.boolean(),
          }),
        }),
      }),
    [formatMessage]
  );

  const initialValues: SettingsForm = useMemo(
    () => ({
      name: store?.name ?? '',
      image: store?.image,
      status: store?.status ?? StoreStatus.INACTIVE,
      defaultVendorId: store?.defaultVendor?.id ?? '',
      configurations: {
        promotions: {
          image: store?.configurations?.promotions?.image,
          enabled: store?.configurations?.promotions?.enabled ?? false,
        },
        miscellaneous: {
          image: store?.configurations?.miscellaneous?.image,
          enabled: store?.configurations?.miscellaneous?.enabled ?? false,
        },
      },
    }),
    [store]
  );

  const handleGenerateImage = async ({
    form,
    name,
    generate,
    value,
  }: HandleGenerateImageProps): Promise<Array<{ name: string; url: string }>> => {
    if (value === 'changed' && generate) {
      const url = await generate();
      form.setFieldValue(name, url);
      return [{ name, url }];
    }

    return [];
  };

  const onSubmit = async (values: SettingsForm, form: FormikHelpers<SettingsForm>) => {
    try {
      const payload = values;

      try {
        const images = [
          ...(await handleGenerateImage({
            generate: imageRef.current?.handleFileUpload,
            name: 'image',
            form,
            value: values.image,
          })),
          ...(await handleGenerateImage({
            generate: promotionsRef.current?.handleFileUpload,
            name: 'configurations.promotions.image',
            form,
            value: values.configurations?.promotions?.image,
          })),
          ...(await handleGenerateImage({
            generate: miscellaneousRef.current?.handleFileUpload,
            name: 'configurations.miscellaneous.image',
            form,
            value: values?.configurations?.miscellaneous?.image,
          })),
        ];

        images.forEach(({ name, url }) => set(payload, name, url));
      } catch (error) {
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'ERROR_COMPONENTS.ERROR_UPLOADING_IMAGE' }),
        });
        return;
      }

      const data = fromPairs(differenceWith(toPairs(payload), toPairs(initialValues), isEqual));

      const changedFields = getDifferentKeys(initialValues, payload);

      await updateStores({
        ...data,
        defaultVendorId: values.defaultVendorId,
        name: values.name,
        id: store!.id,
        country,
        logData: {
          storeId: store!.id,
          defaultVendorName:
            availableVendors.find((vendor) => vendor.id === values.defaultVendorId)?.name ?? '',
          changedFields,
        },
      }).unwrap();
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: 'STORE_SETTINGS_PAGE.UPDATE_STORE_SUCCESS' }),
      });
      navigate(`/company-management/stores/${country}/${store?.id}`, { replace: true });
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({ id: 'ERROR_COMPONENTS.ERROR_GENERIC' }),
      });
    }
  };

  const settingsForms = useFormik<SettingsForm>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const formChanged = useMemo(
    () => !isEqual(settingsForms.initialValues, settingsForms.values),
    [settingsForms]
  );

  return { form: settingsForms, onSubmit: settingsForms.submitForm, formChanged };
};
